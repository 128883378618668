import { toDate } from '@/utils/date'
import filters from '@/utils/filters'
import { TDATO_TDATO_SISTEMA } from '@/utils/consts'
import { v4 as uuidv4 } from 'uuid'

export default {
  async insert (Vue, formData, idsistema) {
    let valor = formData.valor
    if (TDATO_TDATO_SISTEMA.tiposDate.includes(formData.tdato_sistema.tdato_sistema.tipo)) {
      valor = filters.shortDate(toDate(valor))
    }
    await Vue.$offline.datoSistema.insertSync({
      iddato_sistema: uuidv4(),
      idsistema: idsistema,
      idtdato_sistema: formData.tdato_sistema.tdato_sistema.idtdato_sistema,
      valor: valor,
      estado: 1,
      orden: formData.orden,
    })
  },
}
